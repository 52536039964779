import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Banner from '../components/Banner'


export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date
        readTime
        slug
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`

// POSTS ARE RUN THROUGH GATSBY.NODE
const PostTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: { title, category, image, date },
      body,
    }, } = data
  return (
    <>
      <Layout >
        <Hero />
        <Wrapper>
          {/* post info */}
          <article>
            <GatsbyImage
              image={getImage(image)}
              alt={title}
              className="main-img"
            />
            <div className="post-info">
              <h1>{title}</h1>
              <span>{category}</span>
              <p>{date}</p>
              <div className="underline"></div>
            </div>
            <MDXRenderer>{body}</MDXRenderer>
          </article>
        </Wrapper>
        <Banner />

      </Layout>
    </>
  )
}




const Wrapper = styled.section`
  width: 85vw;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 4rem;
   .main-img{
   display:none;
   }

  .post-info {
    margin: 2rem 0 4rem 0;
    text-align: center;
    span {
      background: var(--clr-primary-5);
      color: var(--clr-white);
      border-radius: var(--radius);
      padding: 0.25rem 0.5rem;
      text-transform: uppercase;
      letter-spacing: var(--spacing);
    }
    h1 {
      margin: 2rem 0;
      font-weight: 400;
    }
    p {
    margin-top: 2rem;
      color: var(--clr-grey-5);
    }
    .underline {
      width: 5rem;
      height: 1px;
      background: var(--clr-grey-9);
      margin: 0 auto;
      margin-bottom: 1rem;
    }
  }
  @media (min-width: 992px) {
    & {
      width: 92vw;
    }
    .main-img {
      width: 75%;
      display: block;
      margin: 0 auto;
    }
  }
  @media (min-width: 1170px) {
    & {
      display: grid;
      grid-template-columns: 1fr 200px;
      column-gap: 4rem;
    }
  }
    .nav-center{
      height: 50px;
  width:80vw;
  margin: 0 auto;
  max-width:30vw;
    }
nav{
  margin: 0 auto;
  width: 30rem;
  display:flex;
  height:100%;
  justify-content:space-between;
  }
`

export default PostTemplate
